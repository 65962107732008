<template>
    <div class="order-check">
        <slot name="basic"></slot>
        <div class="check-title" v-if="isPay">选择支付方式</div>
        <div class="check-content" v-if="isPay">
            <el-radio-group
                v-model="radio">
                <el-radio :label="1">
                    <span class="pay-mode pay-balance">账户余额（剩余{{detail.balance}}）</span>
                </el-radio>
                <el-radio :label="2">
                    <span class="pay-mode pay-wechat">微信支付</span>
                </el-radio>
                <el-radio :label="3">
                    <span class="pay-mode pay-ailpay">支付宝</span>
                </el-radio>
            </el-radio-group>
        </div>
        <div class="check-total">
            总金额：
            <span>￥{{detail.all_price}}</span>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                radio: 0
            }
        },
        props: {
            detail: Object,
            isPay: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style scoped>
.order-check {
    border: 1px solid #c0c4cc;
}
.check-title {
    padding: 16px 20px;
    background-color: #e4e7ed;
    font-size: 18px;
}
.check-content {
    padding: 16px 20px;
}
.pay-mode {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding-left: 44px;
    background-repeat: no-repeat;
    background-size: 40px;
}
.pay-balance {
    background-image: url('../../assets/icons/balance.png');
}
.pay-wechat {
    background-image: url('../../assets/icons/wechatpay.png');
}
.pay-ailpay {
    background-image: url('../../assets/icons/alipay.png');
}
.check-total {
    padding: 20px;
    background-color: #dcdfe6;
    text-align: right;
}
.check-total > span {
    font-size: 26px;
    font-weight: 700;
    color: red;
}
</style>